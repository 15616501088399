
import React from "react"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import withStyles from "@material-ui/core/styles/withStyles"
import typographyStyle from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.jsx"

const Introduction = ({ classes }) => (
  <div id="team">
    <div className={classes.center} style={{display: 'flex', justifyContent: 'center'}}>
      <h2>About us</h2>
    </div>
    <br />
    <GridContainer
      container
      direction="row"
      justify="center"
      alignItems="center"
    >
      <GridItem  sm={6}>
        <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</h3>
      </GridItem>
    </GridContainer>
  </div>
)

export default withStyles(typographyStyle)(Introduction)

